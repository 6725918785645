import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import YoutubeEmbed from "../components/YoutubeEmbed";
import FreeResources from "../components/FreeResources";
// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            vucap2021Webinar(id: $id) {
                title
                uri
                excerpt
                content
                webinardate
                youtubeId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
            vucap2021Webinars(first: 2) {
                nodes {
                    id
                    title
                    uri
                    webinardate
                    youtubeId
                    speaker1
                    speaker1Profile
                    speaker2
                    speaker2Profile
                    thumbnail
                }
            }
        }
    }
`

const WebinarTemplate = ({ data }) => {
    const webinar = data.wpcontent.vucap2021Webinar
    const metaDesc = data.wpcontent.vucap2021Webinar.seo.metaDesc
    const pageTitle = data.wpcontent.vucap2021Webinar.seo.title
    const webinars = data.wpcontent.vucap2021Webinars.nodes
    let uri = data.wpcontent.vucap2021Webinar?.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri
    
    // const pageType = data.wpcontent.webinar.seo.opengraphType

    // const [open, setOpen] = useState(false);

    // const onOpenModal = () => setOpen(true);
    // const onCloseModal = () => setOpen(false);
    // let pageurl = `https://www.vantagecircle.com`
    // const uri = webinar.uri
    // if(uri){
    //     pageurl+= `${uri}`
    // }

    return (
        <Layout className="webinar-detail">
            <Seo title={pageTitle}
                description={metaDesc}
                link={[
					{
						rel: `canonical`,
						href: pageUri,
					},
				]}
            />
            <section className="bg-purple-100  mx-auto py-10 sm:py-20 md:py-20 relative mb-20">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-6xl text-center lg:text-left px-6 pb-10">
                   <div className = " w-full justify-center lg:justify-start">
                        <div className="box-label1 hover:bg-purple-800 cursor-auto"><a href="/webinars/vucap-series-2021/">VUCA(P) Series 2021</a></div>
                        <h1 className = "section-title-v1 mb-5">{webinar.title}</h1>
                        <p className="uppercase">Streamed on {webinar.webinardate}</p>
                   </div>
                </div>
            </section>
            <section className="container max-w-6xl pb-20 px-6">
                <div className="md:flex md:items-center">
                    <div className="md:w-1/2">
                        <h2 class="md:mb-10 text-2xl font-semibold text-gray-900 sm:text-4xl">A Few key takeaways</h2>
                        <ul className="form-bullets list-inside orangebullets md:mr-5" dangerouslySetInnerHTML={{ __html: webinar.excerpt }} />
                    </div>
                    <div className="md:w-1/2">
                        <YoutubeEmbed embedId={webinar.youtubeId} />
                    </div>
                </div>
            </section>
            <section className="container max-w-6xl px-6 md:pt-10">
                <div dangerouslySetInnerHTML={{ __html: webinar.content }} />
            </section> 

            <div className="container max-w-6xl px-6 py-20">
                <div className="flex justify-between bg-purple-100 p-7 rounded-lg items-center mb-10">
                    <div className="text-2xl font-bold text-gray-900 sm:text-4xl">Up next</div>
                    <Link to="/webinars/" className="hover:underline">View All Webinars</Link>
                </div>
                <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                    {webinars.map(webinar => (
                        <article key={webinar.id}>
                            <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                                <Link to={`${webinar.uri}`} >
                                    <img className="p-0 rounded-lg webinar-video-thumb" src={`${webinar.thumbnail}`} alt={webinar.title} />
                                </Link>
                                <Link to={`${webinar.uri}`} >
                                    <div className="bg-white py-6 pb-2 h-full topcard no-br">
                                        <p className="text-orange text-base">[On-demand-recording]</p>
                                        <h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3" dangerouslySetInnerHTML={{ __html: webinar.title }} />
                                        <p className="box-subtitle mb-2 uppercase">On <span dangerouslySetInnerHTML={{ __html: webinar.webinardate }} /></p>
                                        <div className="with-sep text-gray-200 text-base">With</div>
                                        <div className="md:flex justify-between">
                                            <div className="box-subtitle w-fl md:h-12">
                                                <span className="font-bold text-lg">{webinar.speaker1}</span> <br/> <span>{webinar.speaker1Profile}</span>
                                            </div>
                                            {webinar.speaker2 && <div className="box-subtitle w-fl md:h-12">
                                                <span className="font-bold text-lg">{webinar.speaker2}</span> <br/> <span>{webinar.speaker2Profile}</span>
                                            </div>}
                                        </div>
                                        
                                    </div>
                                </Link>
                            </div>
                        </article>
                    ))}
                </div>
            </div>

            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className=" p-10 md:p-14 relative z-9 overflow-hidden" style={{"background": "#414171"}}>
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
            
        </Layout>
    )
}

export default WebinarTemplate

